/* --------------------------------
   btn play
-------------------------------- */

.btn-play--big
{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 70px;
	height: 70px;

	&:before,
	&:after
	{
		content: "";
		position: absolute;
		display: block;
		pointer-events: none;
	}

	&:before
	{
		width: 100%;
		height: 100%;
		background-color: #f01562;
		@include border-radius(50%);
		box-shadow: 0 3px 24px rgba(#f01562,.8);
		@include transition( transform 0.3s ease-in-out );
	}

	&:after
	{
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 13px 0 13px 12px;
		border-color: transparent transparent transparent #fff;
	}

	&:hover,
	&:focus
	{
		&:before
		{
			transform: scale(1.05);
		}
	}
}

@include min-screen($sm-width) // 560
{
	.btn-play--big
	{
		width: 100px;
		height: 100px;
	}
}

@include min-screen($md-width) // 768
{

}

@include min-screen($lg-width) // 992
{

}

@include min-screen($xl-width) // 1200
{

}