/* --------------------------------
   goods
-------------------------------- */

$gutter: 30px;

.goods
{
	.__inner { margin-bottom: -$gutter; }

	.__item
	{
		position: relative;
		width: 100%;
		height: 100%;
		margin-bottom: $gutter;

		.__image
		{
			position: relative;
			width: 100%;
			height: 0;
			margin: auto;
			padding-top: percentage(240/270);
			border: 3px solid #f8f8f8;
			overflow: hidden;
			@include border-radius(5px 5px 0 0);

			img
			{
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
				width: auto;
				max-width: 100%;
				max-height: 100%;
				padding: 20px;
			}

			a
			{
				@extend %block-absolute--full;
			}
		}

		.__content
		{
			background-color: #f8f8f8;
			padding: 25px 15px;
			@include border-radius(0 0 5px 5px);
		}

		.__title
		{
			a { text-decoration: none; }
		}

		.__subtitle
		{
			line-height: 1;
			color: #333;
		}

		.__price
		{
			display: inline-block;
			vertical-align: top;
			margin-top: 5px;
			font-size: rem-calc(20px);
			font-weight: 900;
			font-family: 'Lato', sans-serif;
			color: $primary-color;
		}

		p
		{
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.custom-btn { min-width: 135px; }

		&:hover { }
	}
}

@include min-screen($sm-width) // 560
{
	.goods
	{
		.__item
		{
			.__content { padding: 35px 20px; }
		}
	}
}

@include min-screen($md-width) // 768
{
	.goods
	{
		.__item
		{
			.__content { padding: 35px 30px; }
		}
	}
}

@include min-screen($lg-width) // 992
{
	
}

@include min-screen($xl-width) // 1200
{
	
}