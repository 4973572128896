// Default variables
$font_size: 14px !default;
$font_size_small: 12px !default;

$input_border_radius: 5px !default;
$input_height: 40px !default;
$input_height_small: 36px !default;
$dropdown_padding: 15px !default;

$gray_dark: #444 !default;
$gray: #999 !default;
$gray_light: #e8e8e8 !default;
$gray_lighter: #f6f6f6 !default;
$primary_light: $gray !default;
$arrow_color: $gray !default;

// Style the dropdown
.nice-select {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	background-color: #f4f4f4;
	border-radius: $input_border_radius;
	cursor: pointer;
	line-height: 1.2;
	font-family: inherit;
	font-size: $font_size;
	font-weight: normal;
	height: $input_height;
	outline: none;
	padding-left: $dropdown_padding;
	padding-right: $dropdown_padding + 25;
	position: relative;
	text-align: left !important;
	transition: all 0.2s ease-in-out;
	user-select: none;

	&:hover { border-color: darken($gray_light, 5%); }

	&:active,
	&:focus
	{
		border-color: $primary_light;
	}

	// Arrow
	&:after
	{
		border-bottom: 2px solid $arrow_color;
		border-right: 2px solid $arrow_color;
		content: '';
		display: block; 
		height: 5px;
		margin-top: -4px;
		pointer-events: none;
		position: absolute; 
		right: 12px; 
		top: 50%; 
		transform-origin: 66% 66%;
		transform: rotate(45deg);
		transition: all 0.15s ease-in-out;
		width: 5px;
	}

	&.open
	{
		@extend :active;

		&:after { transform: rotate(-135deg); }

		.list
		{
			opacity: 1;
			pointer-events: auto;
			transform: scale(1) translateY(0);
		}
	}

	&.disabled
	{
		border-color: lighten($gray_light, 2%);
		color: $gray;
		pointer-events: none;

		&:after {  border-color: lighten($arrow_color, 20%); }
	}

	// Modifiers
	&.wide
	{
		width: 100%;
		.list
		{
			left: 0 !important;
			right: 0 !important;
		}
	}

	&.right
	{
		float: right;

		.list
		{
			left: auto;
			right: 0;
		}
	}

	&.small
	{
		font-size: $font_size_small;
		height: $input_height_small;
		line-height: $input_height_small - 2;

		&:after
		{
			height: 4px;
			width: 4px;
		}

		.option
		{
			line-height: $input_height_small - 2;
			min-height: $input_height_small - 2;
		}
	}

	.current
	{
		display: block;
		overflow: hidden;
		line-height: $input_height;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	// List and options
	.list
	{
		background-color: #fff;
		border-radius: $input_border_radius;
		box-shadow: 0 0 0 1px rgba($gray_dark, .11);
		box-sizing: border-box;
		margin-top: 4px;
		opacity: 0;
		overflow: hidden;
		padding: 0;
		pointer-events: none;
		position: absolute;
		top: 100%; left: 0;
		transform-origin: 50% 0;
		transform: scale(.75) translateY(- $input_height / 2);
		transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
		z-index: 9;

		&:hover .option:not(:hover) { background-color: transparent !important; }
	}

	.option
	{
		cursor: pointer;
		font-weight: 400;
		min-height: $input_height - 10;
		outline: none;
		padding: 7px $dropdown_padding;
		overflow: hidden;
		text-align: left;
		text-overflow: ellipsis;
		transition: all 0.2s;

		&:hover,
		&.focus,
		&.selected.focus
		{
			background-color: #f4f4f4;
		}

		&.selected { font-weight: bold; }

		&.disabled
		{
			background-color: transparent;
			color: $gray;
			cursor: default;
		}
	}
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select
{
	.list { display: none; }

	&.open {

		.list { display: block; }
	}
}