h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6
{
	line-height: 1.2;
	font-weight: 700;
	color: #404040;
	margin-top: 20px;
	margin-bottom: 20px;
	@include transition(color 0.3s ease-in-out);

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }

	a { color: inherit; }

	.section--dark-bg & { color: $white-color; }
}

h1,.h1
{
	font-size: rem-calc(45px);

	@include min-screen($sm-width)
	{
		font-size: rem-calc(55px);
	}

	@include min-screen($md-width)
	{
		font-size: rem-calc(70px);
	}
}

h2,.h2
{
	font-size: rem-calc(35px);

	@include min-screen($sm-width)
	{
		font-size: rem-calc(40px);
	}
}

h3,.h3
{
	font-size: rem-calc(28px);

	@include min-screen($sm-width)
	{
		font-size: rem-calc(30px);
	}
}

h4,.h4
{
	font-size: rem-calc(25px);
}

h5,.h5
{
	font-size: rem-calc(20px);
}

h6,.h6
{
	font-size: rem-calc(18px);
}

main
{
	blockquote
	{
		margin-top: 25px;
		margin-bottom: 25px;
		padding-left: 20px;
		border-left: 5px solid $primary-color;
		line-height: 1.4;
		font-size: rem-calc(20px);
		color: #333;

		&:first-child { margin-top: 0; }
		&:last-child  { margin-bottom: 0; }

		@include min-screen($sm-width)
		{
			font-size: rem-calc(23px);
		}

		@include min-screen($md-width)
		{
			padding-left: 35px;
			font-size: rem-calc(25px);
		}
	}

	p
	{
		margin-top: 25px;
		margin-bottom: 25px;

		&:first-child { margin-top: 0 !important; }
		&:last-child  { margin-bottom: 0 !important; }
	}

	.dropcaps
	{
		.first-letter
		{
			float: left;
			margin-right: 10px;
			line-height: 1;
			font-size: rem-calc(60px);
			color: $primary-color;
		}
	}
}

a
{
	color: $primary-color;
	text-decoration: underline;
	outline: none;
	@include transition(color 0.3s ease-in-out);

	&:hover,
	&:focus
	{
		color: $primary-color;
		text-decoration: none;
	}
}

.section-heading
{
	&:first-child { margin-top: -5px; }

	.__title { line-height: 1.125; }

	.__subtitle
	{
		line-height: 1.4;
		font-size: rem-calc(16px);
		font-weight: 300;
		font-family: $secondary-fontFamily;
		text-transform: uppercase;
		color: #888a8b;
	}

	&--center
	{
		margin-left: auto;
		margin-right: auto;
		max-width: 600px;
		text-align: center;
	}

	&--white
	{
		.__title,
		.__subtitle
		{
			color: $white-color;
		}
	}
}