.fontello-down:before { content: '\e800'; } /* '' */
.fontello-right-1:before { content: '\e801'; } /* '' */
.fontello-left:before { content: '\e802'; } /* '' */
.fontello-up:before { content: '\e803'; } /* '' */
.fontello-attachment:before { content: '\e804'; } /* '' */
.fontello-avatar:before { content: '\e805'; } /* '' */
.fontello-down-open:before { content: '\e806'; } /* '' */
.fontello-tag:before { content: '\e807'; } /* '' */
.fontello-left-open:before { content: '\e808'; } /* '' */
.fontello-right-open:before { content: '\e809'; } /* '' */
.fontello-up-open:before { content: '\e80a'; } /* '' */
.fontello-calculator:before { content: '\e80b'; } /* '' */
.fontello-document:before { content: '\e80c'; } /* '' */
.fontello-settings:before { content: '\e80d'; } /* '' */
.fontello-phone:before { content: '\e80e'; } /* '' */
.fontello-layer:before { content: '\e80f'; } /* '' */
.fontello-cancel:before { content: '\e810'; } /* '' */
.fontello-wallet:before { content: '\e811'; } /* '' */
.fontello-check:before { content: '\e814'; } /* '' */
.fontello-eye:before { content: '\e820'; } /* '' */
.fontello-plane:before { content: '\e821'; } /* '' */
.fontello-lock:before { content: '\e822'; } /* '' */
.fontello-twitter:before { content: '\f099'; } /* '' */
.fontello-facebook:before { content: '\f09a'; } /* '' */
.fontello-gplus:before { content: '\f0d5'; } /* '' */
.fontello-linkedin:before { content: '\f0e1'; } /* '' */
.fontello-angle-left:before { content: '\f104'; } /* '' */
.fontello-angle-right:before { content: '\f105'; } /* '' */
.fontello-angle-up:before { content: '\f106'; } /* '' */
.fontello-angle-down:before { content: '\f107'; } /* '' */
.fontello-bitcoin:before { content: '\f15a'; } /* '' */
.fontello-youtube:before { content: '\f167'; } /* '' */
.fontello-youtube-play:before { content: '\f16a'; } /* '' */
.fontello-instagram:before { content: '\f16d'; } /* '' */
.fontello-vkontakte:before { content: '\f189'; } /* '' */
.fontello-paper-plane:before { content: '\f1d8'; } /* '' */
.fontello-linkedin-squared:before { content: '\f30c'; } /* '' */