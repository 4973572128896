/* --------------------------------
   gallery
-------------------------------- */

$gutter: 10px;

.gallery
{
	.__inner { margin-bottom: -$gutter; }

	.row
	{
		margin-left: -$gutter/2;
		margin-right: -$gutter/2;
	}

	.col
	{
		padding-left: $gutter/2;
		padding-right: $gutter/2;
	}

	.__item
	{
		position: relative;
		width: 100%;
		margin-bottom: $gutter;
		height: 280px;

		.__image
		{
			position: relative;
			width: 100%;
			height: 100%;
			margin: auto;
			overflow: hidden;

			img
			{
				@extend %block-absolute--full;
				@include object-fit(cover, 50% 50%);
			}
		}

		.__content,
		.__link
		{
			@extend %block-absolute--full;
		}

		.__content
		{
			display: flex;
			align-items: center;
			justify-content: center;
			color: $white-color;
			background-color: rgba($primary-color,0.66);
			padding: 15px;
			opacity: 0;
			font-size: rem-calc(20px);
			@include transition(opacity 0.3s ease-in-out);

			a { color: inherit; }
		}

		&:hover
		{
			.__content { opacity: 1; }
		}
	}
}

@include min-screen($sm-width) // 560
{
	
}

@include min-screen($md-width) // 768
{
	$height-md: 300px;

	.gallery
	{
		.__item
		{
			&[data-y="1"] { height: $height-md; }
			&[data-y="2"] { height: ($height-md*1.5)+$gutter; }
		}
	}
}

@include min-screen($lg-width) // 992
{
	$height-lg: 335px;

	.gallery
	{
		.__item
		{
			&[data-y="1"] { height: $height-lg; }
			&[data-y="2"] { height: ($height-lg*2)+$gutter; }
		}
	}
}

@include min-screen($xl-width) // 1200
{
	
}