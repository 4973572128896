/* --------------------------------
   accordion
-------------------------------- */

.accordion-container
{
	margin-top: 50px;
	margin-bottom: 50px;

	&:first-child { margin-top: 0; }
	&:last-child  { margin-bottom: 0; }
}

.accordion-item
{
	margin-top: -1px;
	border-top: 1px solid #e3e3e3;
	border-bottom: 1px solid #e3e3e3;

	&:first-child
	{
		margin-top: 0;

		article { display: block; }
	}

	article
	{
		display: none;

		p
		{
			margin-top: 15px;
			margin-bottom: 15px
		}
	}

	.__inner { padding-bottom: 20px; }

	&.active .accordion-toggler
	{
		cursor: default;

		i
		{
			color: $primary-color;

			&:before,
			&:after
			{
				transform: rotate(-135deg);
			}
		}
	}
}

.accordion-toggler
{
	position: relative;
	padding: 15px;
	padding-left: 0;
	padding-right: 40px;
	cursor: pointer;

	.__title { margin: 0; }

	i
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 30px;
		height: 30px;
		color: #497bfd;
		border: 2px solid currentColor;
		@include transition(
			background-color 0.3s ease-in-out,
			border-color     0.3s ease-in-out,
			color            0.3s ease-in-out
		);

		&:before,
		&:after
		{
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			border: 1px solid currentColor;
			transform-origin: center;
			@include transition( transform 0.4s ease-in-out );
		}

		&:before
		{
			width: 2px;
			height: 12px;
			margin-left: -1px;
			margin-top: -6px;
		}

		&:after
		{
			width: 12px;
			height: 2px;
			margin-left: -6px;
			margin-top: -1px;
		}
	}

	&:hover
	{
		i { color: $primary-color; }
	}
}

@include min-screen($sm-width)
{
	.accordion-toggler
	{
		padding: 20px 0;
		padding-right: 45px;
	}
}

@include min-screen($md-width)
{

}

@include min-screen($lg-width)
{

}

@include min-screen($xl-width)
{

}