/* --------------------------------
   call to action
-------------------------------- */

.call-to-action
{
	padding-top: 80px;
	padding-bottom: 80px;
}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	
}

@include min-screen($lg-width)
{
	
}

@include min-screen($xl-width)
{
}