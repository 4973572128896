/* --------------------------------
   contact form
-------------------------------- */

$gutter: 30px;

.contact-form
{
	margin-left: auto;
	margin-right: auto;
	max-width: 770px;

	button[type=submit]
	{
		margin-top: 60px;

		&:not(:hover):not(:focus) { color: $white-color; }
	}
}

@include min-screen($sm-width)
{
	
}

@include min-screen($md-width)
{
	
}

@include min-screen($lg-width)
{
	
}

@include min-screen($xl-width)
{
	
}