.text
{
	@include variant(left) {
		text-align: left;
	}

	@include variant(center) {
		text-align: center;
	}

	@include variant(right) {
		text-align: right;
	}
}