/* --------------------------------
   feature
-------------------------------- */

$gutter: 60px;

.feature
{
	.__inner { margin-bottom: -$gutter; }

	.__item
	{
		position: relative;
		width: 100%;
		height: 100%;
		margin-bottom: $gutter;

		.__content { }

		.__ico
		{
			display: inline-block;
			vertical-align: top;
			margin-bottom: 5px;
			height: 60px;
			line-height: 60px;

			img {  }
		}

		.__title { }

		p
		{
			margin-top: 20px;
			margin-bottom: 20px;
		}

		.__more
		{
			display: inline-block;
			vertical-align: top;
			width: 32px;
			height: 32px;
			border: 2px solid $white-color;
			line-height: 28px;
			text-align: center;
			color: inherit;
			@include border-radius(50%);
			@include transition(
				background-color 0.3s ease-in-out,
				color            0.3s ease-in-out
			);

			i
			{
				display: inline-block;
				vertical-align: middle;
				line-height: 1;
				font-size: rem-calc(13px);
			}

			&:hover
			{
				background-color: $white-color;
				color: #292b30;
			}
		}

		&:hover { }
	}
}

.feature--style-1
{
	.__item
	{
		padding: 50px 15px;
		margin-bottom: 0;
		color: $white-color;

		&--first  { background-color: #4262c1; }
		&--second { background-color: #694e96; }
		&--third  { background-color: #43c1a8; }

		.__content
		{
			margin: auto;
			max-width: 360px;
		}

		.__title { color: inherit; }
	}
}

.feature--style-2
{
	
}

.feature--style-3
{
	.__item
	{
		.__ico
		{
			height: 70px;
			line-height: 70px;
		}
	}
}

@include min-screen($sm-width) // 560
{
	
}

@include min-screen($md-width) // 768
{
	.feature--style-1
	{
		.__item
		{
			padding-top: 75px;
			padding-bottom: 75px;
		}
	}
}

@include min-screen($lg-width) // 992
{
	.feature--style-1
	{
		margin-left: 30px;
		margin-right: 30px;

		.__item { padding: 100px 30px; }
	}
}

@include min-screen($xl-width) // 1200
{
	.feature--style-1
	{
		margin-left: 70px;
		margin-right: 70px;

		.grid &
		{
			margin-left: 70px - 15px;
			margin-right: 70px - 15px;
		}
	}
}

@include min-screen(1400px)
{
	.feature--style-1
	{
		margin-left: 90px;
		margin-right: 90px;

		.grid &
		{
			margin-left: 90px - 15px;
			margin-right: 90px - 15px;
		}
	}
}