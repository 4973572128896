/* --------------------------------
   events
-------------------------------- */

$gutter: 30px;

.events
{
	.__inner { margin-bottom: -$gutter; }

	.__item
	{
		position: relative;
		width: 100%;
		background-color: $white-color;
		margin-bottom: $gutter;

		a { text-decoration: none; }

		.__image
		{
			position: relative;
			width: 100%;
			height: 0;
			padding-top: percentage(290/370);
			margin: auto;
			overflow: hidden;

			img
			{
				@extend %block-absolute--full;
				@include object-fit(cover, 50% 50%);
			}
		}

		.__content
		{
			padding: 25px 15px 30px;
		}

		.__title { }

		.__text { }

		.__date-post
		{
			display: block;
			margin-bottom: 35px;
			line-height: 1;
			font-size: rem-calc(20px);
			font-weight: 700;
			text-transform: capitalize;
			color: $primary-color;

			.b-table { width: auto; }

			.__day
			{
				display: block;
				margin-right: 20px;
				line-height: 0.7;
				font-size: rem-calc(80px);
				font-weight: 900;
				font-family: Lato,sans-serif;
			}
		}
	}

	.__item--preview
	{
		box-shadow: 0px 20px 18px 0px transparent;
		@include transition(box-shadow 0.3s ease-in-out);

		.__image
		{
			img
			{
				@include transition(
					transform 700ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
					opacity   200ms
				);
			}
		}

		&:hover
		{
			box-shadow: 0px 29px 18px 0px rgba(154, 154, 154, 0.26);

			.__image
			{
				img { transform: scale(1.2); }
			}
		}
	}

	&--slider
	{
		.slick-track
		{
			display: flex;
			align-items: stretch;
		}

		.slick-slide
		{
			height: auto;
			padding-left: $gutter/2;
			padding-right: $gutter/2;
			padding-bottom: $gutter + 10px;

			&:first-child { margin-left: 0 ; }

			> div:first-child { height: 100%; }
		}

		.slick-dots { margin-top: 0; }

		.__item
		{
			vertical-align: top;
			height: 100%;
			margin-bottom: 0;
		}
	}
}

@include min-screen($sm-width) // 560
{
	.events
	{
		.__item
		{
			.__content
			{
				padding: 30px 25px 40px;
			}
		}

		&--slider
		{
			.slick-dots { margin-top: 50px; }
		}
	}
}

@include min-screen($md-width) // 768
{
	.events
	{
		.__item
		{
			.__content
			{
				padding: 40px 40px 60px;
			}
		}
	}
}

@include min-screen($lg-width) // 992
{
	
}

@include min-screen($xl-width) // 1200
{
	.events
	{
		.grid:not(.grid--container) &
		{
			margin-left: $gutter;
			margin-right: $gutter;
		}
	}
}